.contact-main{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
    
    "contact1 map"
    "datecontact datecontact"
    ;
    margin: 0 10%;
    
    align-items: center;
    column-gap: 10%;


    
}

.datecontact {
    width: 100%;
    margin-top: 5%;
    grid-area: datecontact;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    

    
  }
.map{
    grid-area: map;
    margin-top: 10%;
}

.map-container {
    position: relative;
    width: 100%;
    padding-bottom: 80.25%; /* 16:9 aspect ratio */
  }
  
  .map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  

  @media only screen and (max-width: 480px) {
    .contact-main {
      font-size: medium;
      grid-template-columns: 1fr; /* Single column for small screens */
      margin: 0 5%; /* Adjust margin for smaller screens */
      column-gap: 5%; /* Adjust column gap for smaller screens */
    }
  
    .map-container {
      padding-bottom: 56.25%; /* Adjust aspect ratio for smaller screens */
    }
    .datecontact{
        flex-direction: column;
        
        
    }
    

  }