.cont1{
    display: grid; 
    grid-template-columns: 0.3fr 0.7fr 2.5fr 0.3fr; 
    grid-template-rows: 100px 0.3fr auto; 
    gap: 0px 0px; 
    grid-template-areas: 
      "searcha searcha searcha searcha"
      "filters filters filters filters"
      ". results results ."; 

    grid-auto-flow: row;
    
    
    background-color: var(--s-color);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: #333;


    
    
    
  }
  .searcha { grid-area: searcha; 
    background-color: var(--negru);
    
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
    /* border: 1px solid #ffffff; */
    padding: 2% 5% 2% 5%;
    color: var(--alb);
    
    

}
  .filters { grid-area: filters; 
    background-color: var(--negru);
    color: var(--alb);
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.488); */
    /* border: 1px solid #ffffff52; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 5px solid var(--f-color);
    align-items: center;}
  .results { grid-area: results; 
    background-color: rgba(223, 223, 223, 0.103);
    
    
    padding: 10px;
    }

    .results li {
        text-align: center;
    }

  .filters select {
   
      background-color: #eee;
      border: none;
      padding: 12px 15px;
      margin: 10px 0px 10px 0px;
      flex: 1;
      color: #333;
      width: 100%;
 
  }




  
  .cont1 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .anunt-disp {
    width: 200px;
    margin: 10px;
    
    background-color: #e4e4e4;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /* Add any other styles for the item container */
  }
  
  .anunt-disp img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
    /* Add any other styles for the image */
  }
  
  .anunt-disp h3 {
    font-size: 18px;
    margin-bottom: 5px;
    /* Add any other styles for the heading */
  }
  
  .anunt-disp p {
    font-size: 14px;
    margin-bottom: 10px;
    /* Add any other styles for the text */
  }

  .anuntd-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .anuntd-popup {
    position: relative;
    background-color: #fff;
    width: 50%;
    max-width: 700px;
    height: auto;
    max-height: 90%;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .anuntd-popup img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
    /* Add any other styles for the image */
  }
  
  .anuntd-popup h2 {
    margin-top: 0;
  }
  
  .anuntd-popup button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .anuntd-popup p {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: normal;
    margin: 1px;
    
  }
  
  .filters input, select {
    border-radius: 50px;
  }
  .filters label {
    font-size: small;
    
  }

  .filters div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* Existing styles */

@media (max-width: 768px) {
  /* Adjust the grid columns and rows */
  .cont1 {
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto auto;
    gap: 10px;
  }

  /* Adjust the padding and styling for searcha, filters, and results */
  .searcha,
  .filters,
  .results {
    padding: 10px;
  }

  /* Adjust the width of the filters select */
  .filters select {
    width: 100%;
  }

  /* Adjust the width and margin for anunt-disp */
  .anunt-disp {
    width: 100%;
    margin: 10px 0;
  }

  /* Additional styles can be added as needed */
}

/* Remaining styles remain unchanged */
