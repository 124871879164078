.App {
  text-align: center;
  font-family: 'Chakra Petch', sans-serif;
}

nav {
  
  


  font-family: 'Chakra Petch';
  background-color: black;
  height: 50px;
  
  
  display: flex;
  flex-direction: row;
  color: rgb(209, 212, 212);
  
  z-index: 999;
  
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.55);
  justify-content: space-between;
}

.rightc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  visibility: hidden;
}


#navig{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  


  
}

#navig button{

  margin-right: 20px;
  justify-content: flex-end;
  margin-left: 30px;

}

#logo {
  display: flex;
  color: white; 
  align-items: center;
  
  height: 20px;
  width: auto;
  
  
  
  position: absolute;
  padding: 20px;

  
}


ul {
  list-style-type: none;
  margin: 50px;
  padding: 0;
  display: flex;
  flex: 1;
  align-items: top;
  justify-items: top;
  justify-content: space-between;
}

#navig li{
  display: inline;
  padding: 0 50px;
  justify-self: right;
  text-align: right;
  
}

li {
  display: inline;
  padding: 0 10px;
}

a {
  color: white;
  text-decoration: none;
}

.footer {
  
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  color: black;
  text-align: center;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.55);
  position: static;

  
  
}

.term {
  color: white;
  user-select: none;
}

.term:hover {
  color: #d1d4d4;
}

.termeni {
  z-index: 999;
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;
}


#placuta {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  height: 50px;
  margin-right: 10%;
}

.contact {
  color: #d1d4d4;
  
}

#termeni {
  color: #d1d4d4;
  margin-left: 10%;
}

.table-lista td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 15px;
  
}

table {
  border-collapse: collapse;
  width: 100%;


}

.table-lista tr {
  border-bottom: 1px solid #0a0707;
  background-color: #f2f2f2;
    
}
.table-lista tr:hover {
  background-color: #ddd;
 
}

.table-lista th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffffff;
  color: rgb(2, 2, 2);
  font-size: 15px;
  border: 1px solid #ddd;
}

.table-lista {
  
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #ddd;
  font-size: 15px;
  text-align: center;
}

.adaugareAnunt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  
  border-collapse: collapse;
  border: 2px solid #ddd;
  font-size: 15px;
  text-align: center;
  padding: 5%;
}

select {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
}

input[type=text], input[type=number], input[type=date], input[type=file] {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;

}

.nav-btn {
  display: inline-block;
  font-family: 'Chakra Petch', 'Roboto', sans-serif; 
  background-color: black;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.55);
}

.signinup{
  display: flex;
}

.footer-links{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0%;
  margin-left: 5%;
  
}

#contact{
  color: white;
}

#carouseldiv{
  }

  #carousel {
    

  }

  .contact1{
    margin-top: 10%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0%;
    margin-left: 5%;
    text-align: center;
  }

  .contact1 input{
    border-bottom: 1px solid #ddd;
    margin-bottom: 5%;
  }
  .contact1 h1{
    margin-bottom: 10%;
  }
  #rightbuttons {
    
    margin-left: 65%;
    color: #000;
    
    text-align: center;
  }

  #rightbuttons button{
    
    color: rgb(0, 0, 0);
    border: #000
    
  }
  
  @media only screen and (max-width: 767px) {
    nav {
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .rightc {
      margin: 10px;
      justify-content: center;
    }
  
    #navig {
      padding: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    #navig button {
      margin: 10px;
    }
  
    #logo {
      padding: 10px;
    }
  
    ul {
      margin: 10px;
      justify-content: center;
    }
  
    #navig li {
      padding: 10px;
      text-align: center;
    }
  
    .footer {
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
    }
  
    .termeni {
      margin-left: 5%;
      margin-right: 5%;
    }
  
    #placuta {
      margin: 10px;
    }
  
    .contact {
      margin-right: 10px;
    }
  
    #termeni {
      margin-left: 10px;
    }
  
    .table-lista,
    .adaugareAnunt {
      margin: 5%;
      width: 90%;
      
    }

    .table-div {
      overflow-x: auto;
    }
  
    .nav-btn {
      margin: 10px;
    }
  }
  
  #carouseldiv {
    /* Add any specific styles for the carousel on mobile if needed */
  }
  
  #carousel {
    /* Add any specific styles for the carousel on mobile if needed */
  }  