.searchBar {
    background-color: #eeeeee00;
    border: none;
    padding: 12px 0px;
    
    width: 100%;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    color: #333;

    

    
    
    

}

.searchBar input{
    background-color: var(--black);
    border: none;
    border-bottom: 1px solid var(--alb);
    border-left: 1px solid var(--alb);
    padding: 11px 15px;
    margin: 8px 8px;
    flex: 3;
    border-radius: 0px;
    align-self: baseline;
    color: var(--alb) !important;
}

.searchBar select {
    background-color: var(--negru) !important;
    border: none;
    border-bottom: 1px solid var(--alb);
    margin: 8px 8px;
    flex: 1;
    color: var(--alb) !important;
    border-radius: 0px;
}

.searchBar button{
    border-radius: 0px;
	border: none;
    
    border-right: 1px solid var(--alb);
    border-bottom: 1px solid var(--alb);
	background-color: var(--black);
	color: var(--alb);
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.searchBar button:active {
	transform: scale(0.95);
}

.searchBar button:focus {
	outline: none;
}

/* .searchBar button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
} */

.searchBar option {
    background-color: var(--black);
    color: var(--alb);
    border: none;
    border-bottom: 1px solid var(--alb);
    padding: 12px 15px;
    margin: 8px 8px;
    flex: 1;
    border-radius: 0px;
}

/* Existing styles */

@media (max-width: 768px) {
    /* Adjust the styles for the searchBar on mobile devices */
  
    .searchBar {
      flex-direction: column;
      align-items: stretch;
    }
  
    .searchBar input,
    .searchBar select,
    .searchBar button,
    .searchBar option {
      flex: 1;
      margin: 8px 0;
    }
  }
  
  /* Remaining styles remain unchanged */
  