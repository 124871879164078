
.carouseld .catbtn {
    

    width: 100%;
    background-color: var(--negru);
    color: var(--alb);
    border-radius: 0px;
    border-right: 2px solid #5c5f77;

    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    
    user-select: none;
    z-index: 10;
    text-align: center;
    font-size: 0.8rem;
    
}

.carouseld .catbtn:last-of-type {
    border-right: none;
}
.carouseld .catbtn:hover {
    

    width: 100%;
    background-color: #0c2b34;
    border: 1px solid var(--f-color);
    
    
}

.carouseld {
    background-color: var(--s-color);
    margin-bottom: 10px;
}

.catbtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .category-icon {
    margin-bottom: 8px; /* Adjust as needed to control the space between icon and text */
    font-size: 2.5rem;
  }

  
  /* Adjust the position to maintain the centering */
.react-multiple-carousel__arrow--left {
    left: calc(4% + 1px - 4%);
  }
  
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px - 4%);
  }

  /* Remove the background of the arrows */
.react-multiple-carousel__arrow {
    background: transparent; /* or background: none; */
    color: #3498db; /* Change the text color if needed */
    font-size: 24px; /* Adjust the font size if needed */
    border: none; /* Remove border if there is any */
    box-shadow: none; /* Remove box-shadow if there is any */
  }
  
  .react-multiple-carousel__arrow:hover {
    background: transparent; /* or background: none; */
  }
  
  /* If you want to change the arrow icons, you can use the ::before pseudo-element */
  /* .react-multiple-carousel__arrow::before {
    content: "\2190";
  }
  
  .react-multiple-carousel__arrow--right::before {
    content: "\2192";
  } */

  @media (max-width: 768px) {
    .carouseld .catbtn {
      width: 100%;
      height: 4rem;
      border-radius: 0px;
      border-right: 2px solid #5c5f77;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .carouseld .catbtn:last-of-type {
      border-right: none;
    }
    .carouseld .catbtn:hover {
      width: 100%;
      background-color: #0c2b34;
      border: 1px solid var(--f-color);
    }
    .carouseld {
      background-color: var(--s-color);
      margin-bottom: 10px;
    }
    .catbtn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .category-icon {
      margin-bottom: 8px; /* Adjust as needed to control the space between icon and text */
      font-size: 1.5rem;
    }
    .react-multiple-carousel__arrow--left {
      left: calc(8% + 1px - 8%);
    }
    .react-multiple-carousel__arrow--right {
      right: calc(8% + 1px - 8%);
    }
    .react-multiple-carousel__arrow {
      background: transparent; /* or background: none; */
      color: #3498db; /* Change the text color if needed */
      font-size: 24px; /* Adjust the font size if needed */
      border: none; /* Remove border if there is any */
      box-shadow: none; /* Remove box-shadow if there is any */
    }
    .react-multiple-carousel__arrow:hover {
      background: transparent; /* or background: none; */
    }
  }

  /* @media only screen and (max-width: 768px) {
    .carouseld .catbtn {
        height: auto;
        padding: 15px; 
        font-size: 1rem; 

    .catbtn {
        margin-bottom: 10px; 
    }

    .category-icon {
        margin-bottom: 5px; 
        font-size: 2rem; 
} */