/* search.css */
.search-results {
  margin-top: 20px;
  width: 100%;
  display: flex;
  
}

.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #999494; /* Moved color property to the .cont class */
}

#img-anunt {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.search-results ul {
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  width: 100%;
  min-width: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Added flex-wrap property to allow wrapping */
  gap: 10px;
}

.search-results li {
   border: none;
  
  
  margin-bottom: 10px;
  display: flex;
}

.search-results h3 {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.search-results p {
  margin: 0;
  width: 100%;
}

.search-results .anunt-pr {
  
  background-color: var(--negru);
  display: flex;
  flex-direction: column;
  
  color: var(--alb);
  border-radius: none;
  width: 100%; /* Adjusted width to be 100% */
  max-width: calc(20.00% - 10px); /* Added max-width to limit to one-third of the row */
  box-sizing: border-box; /* Include border and padding in width calculation */
}

.search-results .anunt-pr:hover {
  background-color: var(--negru);
  border: 3px solid var(--f-color);
  color: var(--alb);
  cursor: pointer;
}

.search-results .anunt-pr li {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5%;

}

.search-results .anunt-pr li p {
  font-size: 14px;
  text-align: justify;
  
}

.search-results .anunt-pr li:last-child {
  margin-bottom: 10%;
}

#img-anunt {
  width: 90%;
  height: auto;
  object-fit: cover;
  border-radius: 0px;
  margin: 5%;
}

#titlu {
  margin: 10px;
  width: 100%;
  white-space: normal; 
  overflow: visible;

}


.search-results {
  margin-top: 20px;
  width: 100%;
  display: flex;
  
}


@media screen and (max-width: 768px) {
  .search-results ul {
    flex-direction: row;
    align-items: center; /* Center items in a column */
  }

  .search-results .anunt-pr {
    width: 100%;
    max-width: none; /* Remove max-width */
    flex-basis: 100%; /* Explicitly set flex-basis to 100% for single column layout */
    
  }

  #img-anunt {
    width: 100%;
    margin: 0;
  }

  .search-results li {
    margin-bottom: 10px;
  }
}