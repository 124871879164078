.userProfile {
  display: grid; 
  grid-template-columns: 0.3fr 0.7fr 1.5fr 0.3fr; 
  grid-template-rows: 100px 1.3fr auto; 
  gap: 5px 5px; 
  grid-template-areas: 
    ". numeu numeu ."
    ". profileU profileUdata ."
    ". anuntUdata anuntUdata ."; 
}
.numeu { grid-area: numeu;

 }
.profileU { grid-area: profileU; 
  
  
}
.profileUdata { grid-area: profileUdata; 
}
.anuntU { grid-area: anuntU; 
}
.anuntUdata { grid-area: anuntUdata; 
}

.profileUdata div {
  width: 100%;
  
  
  box-sizing: border-box;

}

/* Existing styles */

/* Media query for mobile devices */
@media (max-width: 768px) {
  .userProfile {
    /* Adjust grid columns and rows for mobile */
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 100px 1fr auto;
    gap: 10px 10px;
    grid-template-areas:
      ". numeu numeu ."
      ". profileU . profileUdata ."
      ". anuntUdata . .";
  }

  /* Adjust the width of .profileUdata div */
  .profileUdata div {
    width: 100%;
  }
}

/* Additional styles can be added as needed */

