.anunt-container {
    display: grid;
    grid-auto-columns: 1fr; 
    grid-template-columns: 0.3fr 2.3fr 1.1fr 0.3fr; 
    grid-template-rows: 0.25fr 2.1fr 0.1fr auto; 
    grid-template-areas: 
    "searcha searcha searcha searcha"
    ". anuntDet utilizator ."
    "asemanatoare asemanatoare asemanatoare asemanatoare"
    "footer footer footer footer";
    margin: 0;
    gap: 20px 20px;

}
.anunt-container .user-details{
    grid-area: utilizator;
    background-color: #ffffff;
    height: 100%;
    margin: 10px;
    padding: 10px;
    align-items: center;
    justify-content: center;

}
.anunt-container .searchaa {
    grid-area: searcha; 
    background-color: var(--negru);
    
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
    /* border: 1px solid #ffffff; */
    padding: 2% 5% 2% 5%;
    color: var(--alb);
    border-bottom: 5px solid var(--f-color);
    margin-bottom: 3%;
}

.anunt-container .profile_img{
    width: 100%;
    height: auto;
    border-radius: 50%;

}


@media only screen and (max-width: 767px) {
    .anunt-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
        "searcha"
        "anuntDet"
        "utilizator";     
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .anunt-container .user-details{
        width: 100%;
        margin: 0;
        padding: 0;

    }
    .anunt-container .searchaa {
        width: 100%;
        margin: 0;
        padding: 4%;
        
    }
    .anunt-container .profile_img{
        width: 100%;
        height: auto;
        border-radius: 50%;

    }
    .anunt-container .anuntDet{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .anunt-container .asemanatoare{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .anunt-container .anuntDet{
        grid-area: anuntDet;
        background-color: #ffffff;
        height: 100%;
        margin: 10px;
        padding: 10px;
        align-items: center;
        justify-content: center;
    
    }
    .anunt-container .asemanatoare{
        grid-area: asemanatoare;
        background-color: #ffffff;
        height: 100%;
        margin: 10px;
        padding: 10px;
        align-items: center;
        justify-content: center;
    
    }
    .anunt-details img{
        width: 100%;
        height: auto;
    }
    .anunt-container p {
        margin: 5px;
    }
}