.container-home {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes at least the viewport height */
}




.home {
    font-family: Roboto;
    background-color: var(--alb);
    display: grid;
    grid-row: auto;
    grid-template-columns: 0.3fr 1.7fr 1.7fr 0.3fr; 
    grid-template-rows: auto auto auto 50px; 
    
    column-gap: 5px;
    grid-template-areas: 
      "search-bar search-bar search-bar search-bar"
      "categories categories categories categories"
      ". search search ."
      "footer footer footer footer"; 
    
    
    
  }

  .bl{
    grid-area: bl;
    background-color: var(--negru);
  }

  .br{
    grid-area: br;
    background-color: var(--negru);
  }
  .r {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: rgba(152, 150, 150, 0.41);
    height: 90%;
    
  }
  .search-bar { grid-area: search-bar;
  padding: 2% 5% 2% 5%;
  background-color: var(--negru);
  
  }
  .categories { grid-area: categories;
    z-index: 5;
    
    background-color:var(--negru);
    border-bottom: 5px solid var(--f-color);
    padding-bottom: 1%;
    
    
   }

  .search { grid-area: search; 
    background-color: var(--s-color);
    z-index: 5;
    min-height: 500px;
  }
  
  .footer { grid-area: footer; 
    background-color: var(--negru);
    z-index: 100;
    
    bottom: 0;
  }

  @media screen and (max-width: 768px) {
    .home {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto 50px;
      grid-template-areas: search-bar
        categories
        search
        footer;
      display: flex;
      flex-direction: column;
    }
  
    .bl,
    .br {
      grid-area: bl;
      grid-area: br;
      background-color: var(--negru);
    }
  
    .r {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 2;
      background-color: rgba(152, 150, 150, 0.41);
      height: 90%;
      display: flex;
    }
  
    .search-bar {
      grid-area: search-bar;
      padding: 0% 5% 2% 5%;
      background-color: var(--negru);
      display: flex;
      flex-direction: column;
    }
  
    .categories {
      grid-area: categories;
      z-index: 5;
      background-color: var(--negru);
      border-bottom: 5px solid var(--f-color);
      padding-bottom: 1%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }
  
    .search {
      grid-area: search;
      background-color: var(--s-color);
      z-index: 5;
      min-height: 500px;
      display: flex;
      flex-direction: column;
    }
  
    .footer {
      grid-area: footer;
      background-color: var(--negru);
      z-index: 100;
      bottom: 0;
    }
  }